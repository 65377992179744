@charset "utf-8";

//transition初期設定
@mixin animation-base($property:all, $duration:.2s, $timing:ease-out) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
}
@keyframes vibrate {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-15deg);
    }
    75% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes vibrate02 {
    0% {
        transform: rotate(0deg) scale(1.2);
    }
    5% {
        transform: rotate(15deg) scale(1.2);
    }
    10% {
        transform: rotate(-15deg) scale(1.2);
    }
    15% {
        transform: rotate(15deg) scale(1.2);
    }
    20% {
        transform: rotate(0deg) scale(1);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}

@media screen and (min-width: 769px){
    /*----------------------------------
    スクロールで画像がふわっと出てくる
    ----------------------------------*/
    .animation--fadeup {
        @include animation-base(all,.7s);
        opacity:0;
        transform:translateY(70px);
        &.active {
            opacity:1;
            transform: translateY(0);
        }
    }
    .animation--fadedown {
        @include animation-base(all,.5s);
        transition-delay: .2s;
        opacity:0;
        transform:translateY(-50px);
        &.active {
            opacity:1;
            transform: translateY(0);
        }
    }
    /*----------------------------------
    ズームする
    ----------------------------------*/
    .animation--zoom{
    overflow: hidden;
    }
    .animation--zoom img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
    /*----------------------------------
    右に動く矢印
    ----------------------------------*/
    @keyframes slideRight {
        0% {
            right: 10px;
            opacity: 1;
        }
        100% {
            right: 0;
            opacity: 0;
        }
    }
    @keyframes slideRight02 {
        0% {
            right: 18px;
            opacity: 1;
        }
        100% {
            right: 0;
            opacity: 0;
        }
    }
}