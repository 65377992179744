@charset "utf-8";

/* =================================
  header
================================= */
.ttl--site {
  display: inline-block;
  a {
    display: block;
    padding: 11px 0;
    img {
      width: auto;
      height: 58px;
    }
    & > span {
      font-size: 1.4rem;
      color: #fff;
      display: inline-block;
      margin: 0 0 0 15px;
    }
  }
}
.header {
  position: relative;
  box-shadow: 0.276px 0.961px 1.9px 0.1px rgba(27, 16, 5, 0.16);
  &__top {
    background: linear-gradient(45deg, #027af5 0%,#f06595 90%);
    width: 100%;
    &__in {
      max-width: $base-width;
      margin: 0 auto;
    }
    &__btn {
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 1;
      color: $font-color-link;
      width: 270px;
      position: absolute;
      top: 0;
      right: calc((100% - 1080px) / 2);
      background: url(/images/bg_border_purple.jpg) repeat left top;
      text-align: center;
      padding: 45px 0 18px;
      &::before {
        content: "";
        width: 21px;
        height: 23px;
        background: url(/images/icon/icon_hBtn.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.gNav {
  max-width: $base-width;
  margin: 0 auto;
  .gNav__list {
    display: flex;
    justify-content: space-between;
    &__item {
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 1;
      border-left: 1px dashed #201a0a;
      width: 25%;
      a {
        display: block;
        position: relative;
        color: $font-color-link;
        text-align: center;
        padding: 50px 0 23px;
        &::before {
          display: inline-block;
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translateX(-50%);
        }
        &:hover {
          color: $accent-color;
          opacity: 1;
        }
      }
      &:nth-child(1) a::before {
        content: "";
        width: 26px;
        height: 24px;
        background: url(/images/icon/icon_gNav01.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(2) a::before {
        content: "";
        width: 24px;
        height: 24px;
        background: url(/images/icon/icon_gNav02.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(3) a::before {
        content: "";
        width: 22px;
        height: 22px;
        background: url(/images/icon/icon_gNav03.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(4) a::before {
        content: "";
        width: 22px;
        height: 19px;
        background: url(/images/icon/icon_gNav04.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(5) a::before {
        content: "";
        width: 20px;
        height: 20px;
        background: url(/images/icon/icon_gNav05.png) no-repeat center center;
        background-size: contain;
      }
      &:last-child a {
        border-right:  1px dashed #201a0a;
      }
    }
  }
}
@media screen and (max-width: 1100px){
  .header__top__btn {
    right: 0;
  }
}
@media screen and (max-width: $display-width-s){
  /* =================================
  header
================================= */
.ttl--site {
  a {
    display: flex;
    align-items: flex-end;
    padding: 7px 0 7px 10px;
    img {
      width: 130px;
      height: auto;
    }
    &> span {
      font-size: 1rem;
      margin: 0 0 0 9px;
      line-height: 1;
      span {
        display: block;
        margin: 2px 0 0;
      }
    }
  }
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: $accent-color;
  &__top {
    width: calc(100% - 45px);
    &__btn {
      font-size: 1.4rem;
      width: 100%;
      display: block;
      padding: 20px 0 20px 45px;
      position: relative;
      top: 0;
      right: 0;
      text-align: left;
      &::before {
        content: "";
        width: 16px;
        height: 18px;
        background: url(/images/icon/icon_hBtn.png) no-repeat center center;
        background-size: contain;
        top: 50%;
        left: 20px;
        transform: translate(0,-50%);
      }
    }
  }
  &Nav__cover {
    height: 0;
    width: 100vw;
    background: rgba(243, 229, 229, 0.8);
    position: absolute;
    top: 45px;
    display: none;
    z-index: -1;
    &.show {
      display: block;
      animation: show .2s linear 0s;
    }
    &.hide {
      display: none;
      animation: hide .2s linear 0s;
    }
  }
}
  .gNav {
    display: none;
    overflow-y: scroll;
    position: relative;
    top: -1px;
    margin: 0;
    padding: 0;
    background: #f3e5e5;
    .gNav__list {
      display: block;
      &__item {
        font-size: 1.4rem;
        width: 100%;
        border-left: none;
        a {
          position: relative;
          text-align: left;
          padding: 20px 10px 20px 50px;
          &::before {
            display: inline-block;
            margin: 0 10px 0 0;
            top: 50%;
            transform: translate(0,-50%);
          }
          &::after {
            content: "";
            width: 7px;
            height: 11px;
            background: url(/images/icon/icon_aroowR.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
        }
        &:nth-child(1) a::before {
          content: "";
          width: 22px;
          height: 20px;
          left: 18px;
        }
        &:nth-child(2) a::before {
          content: "";
          width: 20px;
          height: 20px;
          left: 20px;
        }
        &:nth-child(3) a::before {
          content: "";
          width: 19px;
          height: 19px;
          left: 20px;
        }
        &:nth-child(4) a::before {
          content: "";
          width: 18px;
          height: 15px;
          left: 20px;
        }
        &:nth-child(5) a::before {
          content: "";
          width: 16px;
          height: 16px;
          left: 20px;
        }
        & + .gNav__list__item {
          border-top: 1px dashed #ddd5cb;
        }
        &:last-child a {
          border-right: none;
        }
      }
    }
  }
  /*バーガーボタン設定*/
  .burger {
    width: 28px;
    height: 28px;
    margin: auto;
    position: absolute;
    right: 7px;
    top: 9px;
    cursor: pointer;
    z-index: 205;
    border: 1px solid #fff;
    background: $accent-color;
    border-radius: 2px;
  }
  .burger span {
    width: 13px;
    height: 2px;
    display: block;
    background: #fff;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
    border-radius: 50px;
  }
  .burger .burger--top {
    transform: translateY(-6px);
  }
  .burger .burger--middle {
    transform: translateY(0px);
    position: relative;
    background: none;
  }
  .burger .burger--middle:before {
    content: "";
    display: block;
    position: absolute;
    width: 13px;
    height: 2px;
    background: #fff;
    transition: all 0.2s;
    transform: rotate(0deg);
    border-radius: 50px;
  }
  .burger .burger--middle:after {
    content: "";
    display: block;
    position: absolute;
    width: 13px;
    height: 2px;
    background: #fff;
    transition: all 0.2s;
    transform: rotate(0deg);
    border-radius: 50px;
  }
  .burger .burger--bottom {
    transform: translateY(6px);
  }
  /*クリック後、バツボタンになる*/
  .burger.is-open .burger--top {
    left: 100%;
    width: 0px;
  }
  .burger.is-open .burger--middle:before {
    transform: rotate(135deg);
  }
  .burger.is-open .burger--middle:after {
    transform: rotate(45deg);
  }
  .burger.is-open .burger--bottom {
    right: 100%;
    width: 0px;
  }
}