@charset "utf-8";
/* =================================
  都道府県検索
================================= */
.searchPrefectures {
  &__subTtl {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #cd6fc4;
    margin: 0 0 4px;
  }
  &__block {
    &__list {
      display: flex;
      flex-wrap: wrap;
      li {
        min-width: 60px;
        text-align: center;
        display: inline-block;
        margin: 5px 0 0 5px;
        a {
          font-size: 1.4rem;
          font-weight: bold;
          line-height: 1;
          color: #fff;
          padding: 9px 0;
          display: block;
          background: #cd6fc4;
          border-radius: 2px;
          text-shadow: 1px 0px 2.85px rgba(55, 55, 55, 0.35);
        }
      }
    }
  }
}
/* =================================
  都道府県検索 マップ
================================= */
.searchPrefectures.map {
  background: url(/images/bg_prefectures.png) no-repeat center 80px,#fcf3f8;
  position: relative;
  padding: 45px 0;
  height: 576px;
  .searchPrefectures__item {
    max-width: $base-width;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .searchPrefectures__ttl {
    width: 12rem;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0;
    color: #201a0a;
    background: url(/images/icon/icon_search01.png) no-repeat center 20px,#fff;
    background-size: 23px 23px;
    padding: 54px 34px 14px;
    margin: 0;
    border-radius: 2px;
    box-sizing: content-box;
    position: absolute;
    top: 114px;
    left: 48%;
    transform: translateX(-50%);
  }
  .searchPrefectures__block {
    > li {
      background: #fff;
      display: inline-block;
      padding: 10px 8px 7px;
      border-radius: 2px;
      &.col--3 {
        max-width: 226px;
        li {
          width: calc(33.333333% - 4px);
        }
        li:nth-child(3n + 1) {
          margin-left: 0px;
        }
      }
      &.col--4 {
        max-width: 288px;
        li {
          width: calc(25% - 3.75px);
        }
        li:nth-child(4n + 1) {
          margin-left: 0px;
        }
      }
      &:nth-child(1) {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        &::before {
          content: "";
          width: 112px;
          height: 128px;
          background: url(/images/prefectures__line01.png) no-repeat center center;
          position: absolute;
          left: -112px;
          top: 60px;
          z-index: -1;
        }
      }
      &:nth-child(2) {
        position: absolute;
        top: 140px;
        right: 0;
        z-index: 1;
        &::before {
          content: "";
          width: 114px;
          height: 99px;
          background: url(/images/prefectures__line02.png) no-repeat center center;
          position: absolute;
          left: -120px;
          top: 70px;
          z-index: -1;
        }
      }
      &:nth-child(3) {
        position: absolute;
        top: 20px;
        left: 104px;
        z-index: 1;
        &::before {
          content: "";
          width: 251px;
          height: 199px;
          background: url(/images/prefectures__line05.png) no-repeat center center;
          position: absolute;
          right: -245px;
          top: 65px;
          z-index: -1;
        }
      }
      &:nth-child(4) {
        position: absolute;
        top: 280px;
        right: 30px;
        z-index: 1;
        &::before {
          content: "";
          width: 162px;
          height: 4px;
          background: url(/images/prefectures__line03.png) no-repeat center center;
          position: absolute;
          left: -162px;
          top: 40px;
          z-index: -1;
        }
      }
      &:nth-child(5) {
        position: absolute;
        bottom: 0;
        right: 230px;
        z-index: 1;
        &::before {
          content: "";
          width: 139px;
          height: 60px;
          background: url(/images/prefectures__line04.png) no-repeat center center;
          position: absolute;
          left: -55px;
          top: -30px;
          z-index: -1;
        }
      }
      &:nth-child(6) {
        position: absolute;
        top: 170px;
        left: 63px;
        z-index: 1;
        &::before {
          content: "";
          width: 211px;
          height: 149px;
          background: url(/images/prefectures__line06.png) no-repeat center center;
          position: absolute;
          right: -205px;
          top: 60px;
          z-index: -1;
        }
      }
      &:nth-child(7) {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        &::before {
          content: "";
          width: 93px;
          height: 46px;
          background: url(/images/prefectures__line07.png) no-repeat center center;
          position: absolute;
          right: -93px;
          top: -30px;
          z-index: -1;
        }
      }
    }
    &__list {
      li {
        min-width: 64px;
        a {
          font-size: 1.5rem;
        }
      }
    }
  }
}
/* =================================
  toppage
================================= */
.topSecWrap {
  padding: 48px 0;
  &__ttl {
    text-align: center;
    .ttl--category {
      position: relative;
      padding: 0 0 100px;
      &::after {
        content: "";
        width: 74px;
        height: 70px;
        background: url(/images/icon/icon_topTtl01.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1;
        color: $accent-color;
        display: inline-block;
        &::before {
          content: "";
          width: 16px;
          height: 20px;
          background: url(/images/ttl_slashL.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          vertical-align: bottom;
          margin: 0 5px 0 0;
        }
        &::after {
          content: "";
          width: 16px;
          height: 20px;
          background: url(/images/ttl_slashR.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          vertical-align: bottom;
          margin: 0 0 0 3px;
        }
      }
    }
  }
  &:nth-child(2n) {
    background: #f8f5f6;
  }
}
.mv {
  height: 300px;
  background: url(/images/bg_mv_top.jpg) center bottom no-repeat;
  &Catch {
    text-align: center;
    position: relative;
    &__txt {
      font-size: 3.2rem;
      color: $accent-color02;
      line-height: 1;
      background: rgba(255,255,255,0.9);
      border-radius: 3px;
      display: inline-block;
      padding: 48px 36px;
      position: relative;
      &:after {
        content: "";
        width: calc(100% - 75px);
        border-bottom: 2px dashed $accent-color;
        display: inline-block;
        position: absolute;
        bottom: 31px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .ttl--balloon {
      display: inline-block;
      position: absolute;
      top: -23px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
/*----------------------------------
  ranking
----------------------------------*/
.ranking {
  &__list {
    display: flex;
    li {
      width: calc(20% - 20.8px);
      & + li {
        margin: 0 0 0 26px;
      }
      &:nth-child(1) .label::before {
        background: url(/images/icon/icon_ranking01.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(2) .label::before {
        background: url(/images/icon/icon_ranking02.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(3) .label::before {
        background: url(/images/icon/icon_ranking03.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(4) .label::before {
        background: url(/images/icon/icon_ranking04.png) no-repeat center center;
        background-size: contain;
      }
      &:nth-child(5) .label::before {
        background: url(/images/icon/icon_ranking05.png) no-repeat center center;
        background-size: contain;
      }
    }
  }
  &__item {
    padding: 14px 0;
    .label {
      position: relative;
      &::before {
        content: "";
        width: 42px;
        height: 55px;
        display: inline-block;
        position: absolute;
        top: -4px;
        left: 0px;
      }
      ul {
        margin-left: 50px;
        li {
          font-size: 1.3rem;
          color: #fff;
          line-height: 1;
          text-shadow: 1px 0px 2.85px rgba(55, 55, 55, 0.35);
          background: $accent-color02;
          border-radius: 2px;
          padding: 6px 10px;
          display: inline-block;
          width: auto;
          width: initial;
          margin: 3px 0 0;
          & + li {
            margin: 3px 0 0;
          }
        }
      }
    }
  }
  &__ttl {
    margin: 8px 0 0 50px;
    a {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.714;
      text-decoration: underline;
      display: block;
    }
  }
  &__txt {
    padding: 10px 0 0;
  }
}
/*----------------------------------
  category
----------------------------------*/
.tab--category .category {
  &__list {
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(25% - 22.5px);
      background: #fff;
      & + li {
        margin: 0 0 33px 30px;
      }
      &:nth-child(4n + 1) {
        margin: 0 0 33px 0;
      }
    }
  }
  &__item {
    padding: 17px 15px 20px;
    background: #fff;
  }
  &__ttl {
    a {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.785;
      display: block;
    }
  }
}
/*----------------------------------
  category
----------------------------------*/
.ttl--underLine.merit {
  &::before {
    content: "";
    width: 34px;
    height: 31px;
    background: url(/images/icon/icon_gNav01.png);
    background-size: contain;
    display: block;
    margin: 0 auto 11px;
  }
}
/*----------------------------------
  topSecWrap--under
----------------------------------*/
.meritBox {
  background: #f8f5f6;
  padding: 23px 45px 30px;
  margin: 55px 0 0;
  &__ttl {
    font-size: 2.3rem!important;
    text-align: center;
    margin:  0 0 28px!important;
    background: none!important;
    line-height: 1!important;
    color: $font-color-base!important;
    padding-bottom: 0!important;
    &::before {
      content: "";
      width: 52px;
      height: 57px;
      background: url(/images/icon/icon_merit.png) no-repeat center center;
      background-size: contain;
      display: block;
      margin: 0 auto 15px;
    }
  }
  .meritList {
    margin: 0;
    li {
      font-size: 1.9rem;
      font-weight: bold;
      line-height: 1;
      background: #fff;
      padding: 19px 10px 19px 26px;
      &::before {
        content: "";
        width: 19px;
        height: 20px;
        background: url(/images/icon/icon_check.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        margin-right: 15px;
        position: static;
      }
      & + li {
        margin: 14px 0 0;
        border: none;
      }
    }
  }
}
.popularArticle {
  border: 2px solid #f3eee5;
  background: #fdfafa;
  position: relative;
  min-height: 200px;
  margin: 0 0 56px;
  &::before {
    content: "";
    width: 276px;
    height: 200px;
    background: url(/images/bg_popularArticle_pc.png) no-repeat center center;
    display: inline-block;
    vertical-align: bottom;
    margin-top: 7px;
  }
  &__in {
    width: calc(100% - 276px);
    float: right;
    padding: 50px 34px 34px 24px;
  }
  .data {
    text-align: right;
    margin: 30px 0 0;
  }
}
.time {
  &::before {
    content: "";
    width: 14px;
    height: 15px;
    background: url(/images/icon/icon_day.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    margin-right: 6px;
  }
}
.view {
  margin-left: 20px;
  &::before {
    content: "";
    width: 19px;
    height: 10px;
    background: url(/images/icon/icon_view.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    margin-right: 6px;
  }
}
/* =================================
  article list
================================= */
.articleList {
  margin: 20px 0 50px;
  li {
    display: flex;
    align-items: flex-start;
    background: #fcf3f8;
    .thumb {
      width: 280px;
      img {
        width: 100%;
        height: auto;
      }
    }
    & + li {
      margin: 28px 0 0;
    }
  }
  &__item {
    width: calc(100% - 280px);
    background: #fcf3f8;
    padding: 23px 30px;
    a {
      font-size: 1.8rem;
      line-height: 1.6;
      color: $font-color-base;
    }
    .data {
      margin: 15px 0 0;
    }
  }
}
.pagenation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 980px;
  margin: 20px auto 40px;
  .pager {
    li {
      display: inline-block;
      a {
        line-height: 1;
        background: #fcf3f8;
        padding: 8px 11px;
        display: block;
        border-radius: 3px;
        &:hover {
          color: #fff;
          background: $accent-color;
        }
      }
      span {
        line-height: 1;
        color: #fff;
        background: $accent-color;
        padding: 8px 11px;
        display: block;
        border-radius: 3px;
      }
      & + li {
        margin-left: 3px;
      }
    }
  }
}
/* =================================
  article detail
================================= */
.article__detail {
  .data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;
    .categoryLabel {
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1;
      background: url(/images/bg_border_yellow.jpg) repeat left top;
      border-radius: 3px;
      padding: 9px 12px;
    }
  }
  .tocBox {
    margin: 30px 0 40px;
    padding: 22px 30px 24px;
    background: #fcf3f8;
    &__ttl {
      font-size: 1.4rem;
      font-weight: bold;
      color: $font-color-base;
      background: none;
      padding: 0;
      margin: 0;
      &::before {
        content: "";
        width: 14px;
        height: 16px;
        background: url(/images/icon/icon_toc.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .tocList {
      margin: 15px 0 0;
      &__item {
        padding: 0;
        a {
          font-size: 1.4rem;
          font-weight: normal;
          color: $font-color-base;
          text-decoration: underline;
          &:hover {
            color: $accent-color;
            text-decoration: none;
          }
        }
        & + .tocList__item {
          margin: 10px 0 0;
        }
        &::before {
          content: none!important;
        }
      }
    }
  }
  h1 {
    font-size: 2.9rem;
    line-height: 1.48275;
    margin: 0 0 25px;
  }
  h2 {
    font-size: 2.3rem;
    line-height: 1.5652;
    color: #fff;
    background: url(/images/bg_border_green.jpg) repeat left top;
    border-radius: 2px;
    padding: 16px 22px;
    margin: 0 0 20px;
  }
  h3 {
    font-size: 2rem;
    color: $accent-color;
    line-height: 2;
    background: url(/images/bg_ttl03.jpg) repeat-x left bottom;
    padding-bottom: 2px;
    margin: 0 0 20px;
  }
  h4 {
    font-size: 1.7rem;
    line-height: 1.6941;
    background: #fcf3f8;
    border-radius: 2px;
    padding: 14px 18px;
    margin: 0 0 30px;
  }
  h5 {
    font-size: 1.8rem;
    line-height: 1.6;
    padding: 0 0 15px;
    margin: 0 0 25px;
    border-bottom: 1px dashed $font-color-base;
  }
  strong {
    color: $font-color-base;
    background: linear-gradient(transparent 65%, #f9ef80 0%);
    display: inline;
    padding: 0 4px;
  }
  em {
    color: $accent-color02;
  }
  img {
    display: block;
    margin: 0 auto 40px;
  }
  table {
    margin: 43px 0 43px;
    caption {
      font-size: 1.6rem;
      font-weight: 600;
      margin: 0 0 15px;
    }
    th {
      font-size: 1.5rem;
    }
  }
  ul:not([class]) {
    margin: 10px auto;
    li {
      font-size: 1.5rem;
      line-height: 1.35;
      position: relative;
      padding: 10px 7px 7px 30px;
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        background: url(/images/icon/icon_check.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 5px;
      }
      &+ li {
        border-top: 1px dashed $accent-color02;
      }
    }
  }
  ol {
    counter-reset: counterCircle;
    margin: 20px auto;
    li {
      font-size: 1.5rem;
      line-height: 1.35;
      position: relative;
      padding: 10px 0 0 30px;
      &::before {
        content: "";
        content: counter(counterCircle);
        counter-increment: counterCircle;
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        background-color: $accent-color;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        padding: 4px 7px;
        position: absolute;
        left: 0;
        top: 10px;
      }
      &+ li {
        margin: 15px 0 0;
        border-top: 1px dashed #cac3b4;
      }
    }
  }
  blockquote {
    position: relative;
    padding: 5px 12px 5px 56px;
    margin: 20px 0;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    background: #f3f9e7;
  }
  blockquote:before{
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 10px;
    width: 36px;
    height: 36px;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    vertical-align: middle;
    text-align: center;
    content: "“";
    color: #FFF;
    font-size: 7rem;
    line-height: 1.2;
    text-indent: -0.4em;
    background: $accent-color02;
    border-radius: 50%;
  }
  blockquote p {
    position: relative;
    padding: 0;
    margin: 10px 0;
    z-index: 3;
    line-height: 1.7;
  }
  blockquote cite {
    display: block;
    text-align: right;
    line-height: 1;
    color: #888888;
    font-size: 0.9em;
    margin: 10px 0 0;
  }
  .related {
    background: #fcf3f8;
    padding: 0 20px 23px;
    margin: 57px 0 70px;
    &__list {
      margin: 0;
      &__item {
        display: flex;
        align-items: flex-start;
        background: #fff;
        padding: 0;
        .thumb {
          width: 200px;
          img {
            margin: 0;
          }
        }
        .related__txt {
          width: calc(100% - 200px);
          background: #fff;
          padding: 20px 30px;
          margin: 0;
          a {
            font-size: 1.5rem;
            color: $font-color-base;
          }
        }
      }
    }
    &__ttl {
      font-size: 1.6rem;
      line-height: 1;
      color: #fff;
      display: inline-block;
      background: url(/images/bg_border_green.jpg) repeat left top;
      padding: 9px 15px;
      margin: 0 0 8px;
      border-radius: 3px;
      position: relative;
      top: -17px;
      z-index: 10;
      &::before {
        content: none;
      }
      &::after {
        content: "";
        width: 30px;
        height: 40px;
        background: url(/images/balloonArrow02.png) no-repeat left top;
        background-size: contain;
        display: block;
        position: absolute;
        left: 40px;
        bottom: -13px;
        z-index: -1;
      }
    }
  }
  .pointBox {
    background: url(/images/bg_point.png) no-repeat right 22px,#fcf3f8;
    padding: 0 43px 15px;
    margin: 45px 0 20px;
    &__ttl {
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 1;
      background: url(/images/bg_border_yellow.jpg) repeat left top;
      display: inline-block;
      padding: 9px 12px;
      margin: 0 0 10px;
      position: relative;
      border-radius: 3px;
      top: -15px;
      z-index: 10;
      &::after {
        content: "";
        width: 30px;
        height: 40px;
        background: url(/images/balloonArrow03.png) no-repeat left top;
        background-size: contain;
        display: block;
        position: absolute;
        top: 8px;
        left: 0;
        z-index: -1;
      }
    }
    h5 {
      width: calc(100% - 100px);
    }
  }
  .imgBox {
    margin: 35px 0 50px;
    a {
      display: block;
      &:hover {
        opacity: 0.7;;
      }
    }
  }
}
.related {
  padding: 27px 40px 37px;
  margin: 38px 0 0;
  background: #fcf3f8;
  &__ttl {
    font-size: 2rem!important;
    font-weight: bold;
    text-align: center;
    margin: 0 0 28px!important;
    &::before {
      content: "";
      width: 53px;
      height: 55px;
      background: url(/images/icon/icon_related.png) no-repeat center center;
      background-size: contain;
      display: block;
      margin: 0 auto 14px;
    }
  }
  &__list {
    &__item {
      background: #fff;
      display: flex;
      align-items: center;
      .thumb {
        width: 160px;
      }
      .related__txt {
        width: calc(100% - 160px);
        padding: 24px;
        a {
          line-height: 1.92857;
          color: $font-color-base;
        }
      }
      & + .related__list__item {
        margin: 22px 0 0;
      }
    }
  }
}
.underSecBox {
  border-top: 5px solid #f3eee5;
  background: #fdfafa;
  padding: 50px 0 60px;
}

/* =================================
  税理士一覧
================================= */
.listCard {
  border: 1px solid #f3eee5;
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 920px;
  margin: 0 auto;
  box-sizing: content-box;
  position: relative;
  & + .listCard {
    margin: 40px auto 0;
  }
  &__r {
    width: 420px;
  }
  &__l {
    width: calc(100% - 450px);
  }
  .btn--mailForm {
    font-size: 1.7rem;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    background: $accent-color02;
    display: inline-block;
    padding: 12px 35px;
    position: absolute;
    bottom: 32px;
    right: 90px;
    &::before {
      content: "";
      width: 18px;
      height: 12px;
      background: url(/images/icon/icon_mail.png);
      background-size: contain;
      display: inline-block;
      margin-right: 15px;
    }
    &:hover {
      box-shadow: 0.276px 0.961px 2.85px 0.15px rgba(22, 37, 8, 0.28);
    }
  }
}
.tagBox {
  display: flex;
  margin: 0 0 18px;
  li {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    background: #d9b6ee;
    padding: 9px 12px;
    & + li {
      margin-left: 5px;
    }
  }
}
.name {
  margin: 0 0 23px;
  h2 {
    font-size: 2.9rem;
    font-weight: bold;
    color: $font-color-base;
    line-height: 1;
  }
  a {
    font-size: 2.9rem;
    font-weight: bold;
    color: $font-color-base;
    line-height: 1;
    &:hover {
      color: $accent-color;
    }
  }
  .catch {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1;
    color: $accent-color;
    margin: 0 0 20px;
    display: block;
  }
}
.info {
  margin:  0 0 20px;
  address {
    font-style: normal;
    display: inline-block;
    padding-left: 45px;
  }
  tr {
    border-top: 1px dashed #959595;
    &:last-child {
      border-bottom: 1px dashed #959595;
    }
    th {
      border: none;
    }
    td {
      position: relative;
      padding: 10px 0;
      border: none;
    }
  }
  .address {
    td::before {
      content: "";
      width: 17px;
      height: 25px;
      background: url(/images/icon/icon_pin.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .access {
    td::before {
      content: "";
      width: 20px;
      height: 25px;
      background: url(/images/icon/icon_access.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
}
.feature {
  margin: 0 0 24px;
  dt {
    font-size: 1.5rem;
    color: #fff;
    line-height: 1;
    text-shadow: 0.276px 0.961px 1.9px rgba(27, 16, 5, 0.14);
    padding: 7px 10px;
    background: $accent-color02;
    display: inline-block;
    vertical-align: top;
  }
  dd {
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
  }
}
.contactBox {
  border: 2px solid $accent-color02;
  border-radius: 3px;
  padding: 7px 8px 7px 19px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .tel {
    &Ttl {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      color: $accent-color;
      margin: 10px 0 18px;
    }
    a {
      address {
        font-size: 3.6rem;
        font-style: normal;
        color: $font-color-base;
        line-height: 1;
        display: inline-block;
        &::before {
          content: "";
          width: 25px;
          height: 25px;
          background: url(/images/icon/icon_tel.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          vertical-align: baseline;
          margin-right: 12px;
        }
      }
      &:hover {
        address {
          color: $accent-color;
          &::before {
            animation-duration: .3s;
            animation-timing-function: liner;
            animation-name: vibrate;
          }
        }
      }
    }
  }
  .receptionTime {
    font-size: 1.3rem;
    background: #f3f9e7;
    padding: 15px 13px;
    max-width: 120px;
    dt {
      color: $accent-color;
      margin: 0 0 3px;
    }
  }
}
/* =================================
  税理士詳細
================================= */
.clientSummary {
  background: #fff;
  position: relative;
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 13.5px 0 13.5px;
    border-color: #fff transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  &__in {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 53px 0 43px;
    max-width: 980px;
    margin: 0 auto;
    position: relative;
    .tagBox {
      position: absolute;
      left: 0;
    }
  }
  &__r {
    width: 420px;
    margin-left: 30px;
  }
  &__l {
    padding-top: 53px;
    width: calc(100% - 450px);
  }
}
.clientPoint {
  background: #f8f5f6;
  padding: 47px 0 40px;
  &::before {
    content: "";
    width: 46px;
    height: 63px;
    background: url(/images/icon/icon_point.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    margin: 0 0 15px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  &__in {
    max-width: 980px;
    margin: 0 auto;
  }
  &__ttl {
    font-size: 2.8rem;
    color: $accent-color;
    display: block;
    text-align: center;
    margin: 0 0 25px;
    &::before {
      content: "";
      width: 26px;
      height: 36px;
      background: url(/images/ttl_slashL.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
    }
    &::after {
      content: "";
      width: 26px;
      height: 36px;
      background: url(/images/ttl_slashR.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__box {
    background: url(/images/bg_border_green.jpg) repeat left top;
    border-radius: 3px;
    padding: 7px;
    &__in {
      padding: 35px 43px 30px;
      background: #fff;
      border-radius: 3px;
    }
    p {
      border-bottom: 1px dashed #d2d2d2;
      display: inline;
      padding-bottom: 10px;
      line-height: 2.8;
    }
    ul {
      border: 1px solid $accent-color02;
      padding: 13px 25px;
      margin: 32px 0 0;
      li {
        padding: 17px 7px 17px 30px;
        position: relative;
        &::before {
          content: "";
          width: 16px;
          height: 16px;
          background: url(/images/icon/icon_check.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          position: absolute;
          top: 20px;
          left: 5px;
        }
        + li {
          border-top: 1px dashed $accent-color02;
         }
      }
    }
  }
}
/*map*/
.clientMap {
  background: #fff;
  padding: 35px 0 77px;
  text-align: center;
  .ttl--underLine.map {
    &::before {
      content: "";
      width: 26px;
      height: 38px;
      background: url(/images/icon/icon_map.png) no-repeat center center;
      background-size: contain;
      display: block;
      margin: 0 auto 15px;
    }
  }
  &__inner {
    position: relative;
    outline: 8px solid rgba(134, 192, 22, 0.4);
  }
}
/*料金体系*/
.clientPrice {
  background: #fdfafa;
  padding: 35px 0 42px;
  text-align: center;
  .ttl--underLine.price {
    &::before {
      content: "";
      width: 25px;
      height: 36px;
      background: url(/images/icon/icon_price.png) no-repeat center center;
      background-size: contain;
      display: block;
      margin: 0 auto 15px;
    }
  }
  &__in {
    max-width: 980px;
    margin: 0 auto;
    h3 {
      font-size: 2rem;
      margin: 40px 0 20px;
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
    margin: 0 0 20px;
    thead th {
      text-align: center;
      padding: 20px 0;
    }
    th {
      font-size: 1.8rem;
      line-height: 1;
      color: $accent-color;
      background: url(/images/bg_table.jpg) repeat left top;
      border: 1px solid #dcd0d3;
      padding: 20px 5px 20px 20px;
    }
    td {
      font-size: 1.8rem;
      line-height: 1;
      border: 1px solid #dcd0d3;
      background: #fff;
      padding: 20px 5px 20px 40px;
      text-align: left;
      em {
        font-weight: bold;
        color: #d33701;
      }
    }
  }
}
/*suggest*/
.clientSuggest {
  background: url(/images/bg_border_green02.jpg) repeat left top;
  padding: 30px 0 23px;
  &__in {
    max-width: 710px;
    margin: 0 auto;
  }
  .ttl--suggest {
    font-size: 3.6rem;
    font-weight: bold;
    color: $accent-color;
    text-align: center;
    > span {
      display: block;
      font-size: 2.6rem;
      color: #534826;
      margin: 0 0 15px;
      &::before {
        content: "";
        width: 23px;
        height: 38px;
        background: url(/images/ttl_slashL02.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
      }
      &::after {
        content: "";
        width: 23px;
        height: 38px;
        background: url(/images/ttl_slashR02.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        left: -10px;
      }
      span {
        font-size: 3.3rem;
        position: relative;
        &::before {
          content: "・";
          font-size: 2rem;
          position: absolute;
          top: -15px;
          left: 7px;
        }
        &::after {
          content: "・";
          font-size: 2rem;
          position: absolute;
          top: -15px;
          right: 7px;
        }
      }
    }
  }
  .contactBox {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(5, 1, 1, 0.4);
    border: none;
    padding: 14px 14px 14px 30px;
    margin: 30px 0 0;
    .tel a address {
      font-size: 4.2rem;
      &::before {
        width: 30px;
        height: 30px;
      }
    }
    .receptionTime {
      font-size: 1.5rem;
      font-weight: bold;
      max-width: 330px;
    }
  }
  .btn--mailForm {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1;
    color: $accent-color;
    padding: 8px 28px 8px 38px;
    border: 2px solid $accent-color;
    border-radius: 2px;
    display: inline-block;
    margin: 17px 0 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &::before {
      content: "";
      width: 18px;
      height: 12px;
      background: url(/images/icon/icon_mail02.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    &:hover {
      background: rgba(255,255,255,0.6);
    }
  }
}
.clientDetail {
  background: #fcf3f8;
  padding: 12px 24px 30px;
  margin: 70px 0 0;
  &__ttl {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    display: inline-block;
    background: url(/images/bg_border_yellow.jpg) repeat left top;
    padding: 9px 15px;
    border-radius: 3px;
    position: relative;
    top: -30px;
    &::after {
      content: "";
      width: 8px;
      height: 12px;
      background: url(/images/balloonArrow03.png) no-repeat left top;
      background-size: contain;
      display: inline-block;
      position: absolute;
      bottom: -11px;
      left: 0;
    }
  }
  table {
    margin: 0;
    th {
      font-size: 1.5rem;
      background: url(/images/bg_border_brown.jpg) repeat left top;
      color: #fff;
    }
    td {
      font-size: 1.5rem;
      background: #fff;
    }
  }
}
.underContact {
  background: #faf9f5;
  margin: 65px auto -56px;
  padding: 40px 0 87px;
}
/* =================================
  お問い合わせ
================================= */
.underSecBox.contact {
  margin-bottom: -56px;
}
.contactSec__ttl {
  text-align: center;
  &::before {
    content: "";
    width: 30px;
    height: 22px;
    display: inline-block;
    background: url(/images/icon/icon_mail03.png) no-repeat center center;
    background-size: contain;
    margin: 0 0 10px;
  }
}
.contactForm {
  max-width: 980px;
  margin: 0 auto;
  border: 3px solid #e8e3d9;
  background: #fff;
  padding: 34px 54px 39px;
  .step {
    display: flex;
    justify-content: center;
    margin: 0 0 36px;
    li {
      text-align: center;
      font-weight: bold;
      color: #fff;
      width: 214px;
      position: relative;
      opacity: .5;
      .num {
        font-size: 1.3rem;
        line-height: 46px;
        background: #f386ac;
        display: inline-block;
        vertical-align: bottom;
        width: 50%;
      }
      .txt {
        font-size: 1.8rem;
        line-height: 46px;
        background: $accent-color02;
        display: inline-block;
        vertical-align: bottom;
        width: 50%;
      }
      & + li {
        margin-left: 30px;
      }
      &.current {
        opacity: 1;
      }
      &:nth-child(-n + 2)::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 12px;
        border-color: transparent transparent transparent $accent-color02;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
      }
    }
  }
  tr {
    border-top: 1px dashed #e8e8e8;
    &:last-child {
      border-bottom: 1px dashed #e8e8e8;
    }
  }
  th {
    font-size: 1.5rem;
    font-weight: normal;
    color: #000;
    border: none;
    background: #fcf3f8;
    vertical-align: top;
    padding: 30px 24px;
    width: 27%;
  }
  td {
    font-size: 1.6rem;
    color: #000;
    border: none;
    padding: 30px 20px 30px 45px;
    p {
      margin: 0;
    }
    * {
      font-size: 1.6rem;
    }
    input,textarea {
      background: #fcf3f8;
      border: 1px solid #e8e8e8;
      width: 100%;
      padding: 5px;
    }
    textarea {
      min-height: 200px;
    }
  }
  .required {
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 1;
    color: #fff;
    background: $accent-color;
    display: inline-block;
    padding: 5px 6px 5px 8px;
    margin-left: 15px;
  }
  .confirmingTxt {
    font-size: 1.4rem;
    text-align: center;
    margin: 37px 0;
    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        color: $accent-color;
      }
    }
  }
}
.completedForm {
  .completedMessage {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.06em;
    color: #d33701;
    display: inline-block;
    margin: 24px 0 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background: #d33701;
      display: inline-block;
    }
  }
  p {
    font-size: 1.5rem;
    text-align: center;
    margin: 40px 0 0;
  }
  table {
    width: initial;
    margin: 40px auto 45px;
  }
  th,td {
    width: auto;
  }
  th {
    font-size: 1.6rem;
    font-weight: bold;
    vertical-align: middle;
    padding: 15px 30px;
  }
  td {
    vertical-align: middle;
    padding: 15px 30px;
    .tel {
      address {
        font-size: 3.4rem;
        font-style: normal;
        line-height: 1;
        color: $font-color-base;
        &::before {
          content: "";
          width: 25px;
          height: 25px;
          background: url(/images/icon/icon_tel.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          vertical-align: baseline;
          margin-right: 12px;
        }
        &:hover {
          color: $accent-color;
          &::before {
            animation-duration: .3s;
            animation-timing-function: liner;
            animation-name: vibrate;
          }
        }
      }
    }
    .receptionTime {
      margin: 5px 0 0;
      dt {
        font-size: 1.3rem;
        font-weight: bold;
        color: $accent-color;
      }
      dd {
        font-size: 1.3rem;
        margin-left: 10px;
      }
      dt,dd {
        display: inline-block;
      }
    }
  }
}
/* =================================
  single page
================================= */
.single__ttl {
  text-align: center;
}
.singleCont {
  max-width: 980px;
  margin: 0 auto;
  border: 3px solid #e8e3d9;
  background: #fff;
  padding: 34px 54px 39px;
}
@media screen and (max-width: $display-width-s){
  /* =================================
    都道府県検索
  ================================= */
  .searchPrefectures {
    &__subTtl {
      font-size: 1.5rem;
    }
  }
  /* =================================
    都道府県検索 マップ
  ================================= */
  .searchPrefectures.map {
    background: #fcf3f8;
    padding: 25px 10px 20px;
    height: initial;
    .searchPrefectures__item {
      height: 100%;
      margin: 0 auto;
      position: relative;
    }
    .searchPrefectures__ttl {
      width: 100%;
      font-size: 1.8rem;
      line-height: 1;
      letter-spacing: 0.08em;
      background: url(/images/icon/icon_search01.png) no-repeat center top;
      background-size: 20px 20px;
      padding: 28px 0 0;
      margin: 0 0 17px;
      border-radius: 0;
      position: static;
      transform: translateX(0);
      text-align: center;
    }
    .searchPrefectures__block {
      > li {
        display: block;
        padding: 10px;
        &.col--3 {
          max-width: initial;
          li {
            width: calc(25% - 3.75px);
          }
          li:nth-child(3n + 1) {
            margin-left: 5px;
          }
          li:nth-child(4n + 1) {
            margin-left: 0px;
          }
        }
        &.col--4 {
          max-width: initial;
        }
        &:nth-child(1) {
          position: static;
          &::before {
            content: none;
          }
        }
        &:nth-child(2) {
          position: static;
          &::before {
            content: none;
          }
        }
        &:nth-child(3) {
          position: static;
          &::before {
            content: none;
          }
        }
        &:nth-child(4) {
          position: static;
          &::before {
            content: none;
          }
        }
        &:nth-child(5) {
          position: static;
          &::before {
            content: none;
          }
        }
        &:nth-child(6) {
          position: static;
          &::before {
            content: none;
          }
        }
        &:nth-child(7) {
          position: static;
          &::before {
            content: none;
          }
        }
        & + li {
          margin-top: 10px;
        }
      }
    }
  }

  /* =================================
    toppage
  ================================= */
  .topSecWrap {
    padding: 17px 10px 0;
    &__ttl {
      margin: 14px 0 0;
      padding: 0 20px;
      .ttl--category {
        padding: 0 0 68px;
        &::after {
          content: "";
          width: 49px;
          height: 47px;
          bottom: 11px;
        }
        span {
          font-size: 1.4rem;
          &::before {
            width: 9px;
            height: 12px;
          }
          &::after {
            width: 9px;
            height: 12px;
          }
        }
      }
    }
    &:nth-child(2n) {
      background: #f8f5f6;
    }
  }

  .mainWrap {
    margin: 45px 0 0;
  }
  .mv {
    height: 200px;
    background: url(/images/bg_mv_top.jpg) center bottom no-repeat;
    background-size: cover;
    &Catch {
      &__txt {
        width: 90%;
        font-size: 2.4rem;
        line-height: 1.25;
        padding: 12px 13px 15px;
        margin: 0 auto;
        position: relative;
        &:after {
          content: "";
          width: calc(100% - 50px);
          border-bottom: 1px dashed $accent-color;
          display: inline-block;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .ttl--balloon {
        position: relative;
        top: 0;
        display: block;
        margin: 0 auto 8px;
        span {
          font-size: 1.6rem;
        }
      }
    }
  }
  /*----------------------------------
    ranking
  ----------------------------------*/
  .ranking {
    &__list {
      display: block;
      li {
        width: 100%;
        display: flex;
        position: relative;
        padding: 11px 0 15px;
        border-bottom: 1px solid #dedddd;
        &:last-child {
          border-bottom: none;
        }
        & + li {
          margin: 0;
        }
        &:nth-child(1) .label::before {
          background: url(/images/icon/icon_ranking01.png) no-repeat center center;
          background-size: contain;
        }
        &:nth-child(2) .label::before {
          background: url(/images/icon/icon_ranking02.png) no-repeat center center;
          background-size: contain;
        }
        &:nth-child(3) .label::before {
          background: url(/images/icon/icon_ranking03.png) no-repeat center center;
          background-size: contain;
        }
        &:nth-child(4) .label::before {
          background: url(/images/icon/icon_ranking04.png) no-repeat center center;
          background-size: contain;
        }
        &:nth-child(5) .label::before {
          background: url(/images/icon/icon_ranking05.png) no-repeat center center;
          background-size: contain;
        }
        .widelink__cover {
          width: 98px;
          margin: 25px 0 36px;
        }
      }
    }
    &__item {
      padding: 0 0 0 12px;
      width: calc(100% - 98px);
      .label {
        position: static;
        &::before {
          content: "";
          width: 31px;
          height: 41px;
          top: 25px;
          left: -3px;
        }
        ul {
          margin: 0 0 10px;
          li {
            font-size: 1.4rem;
            padding: 4px 7px;
            margin: 3px 0 0;
            & + li {
              margin: 3px 0 0;
            }
          }
        }
      }
  
    }
    &__ttl {
      margin: 0;
      a {
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 1.53;
      }
    }
    &__txt {
      padding: 10px 0 0;
      font-size: 1.3rem;
      line-height: 1.3636;
      margin: 0;
    }
  }
  /*----------------------------------
    category
  ----------------------------------*/
  .tab--category .category {
    &__list {
      li {
        width: calc(50% - 11px);
        & + li {
          margin: 0 0 15px 22px;
        }
        &:nth-child(4n + 1) {
          margin: 0 0 15px 22px;
        }
        &:nth-child(2n + 1) {
          margin: 0 0 15px 0;
        }
      }
    }
    &__item {
      padding: 12px 9px;
    }
    &__ttl {
      a {
        font-size: 1.4rem;
        line-height: 1.66666;
      }
    }
  }
  /*----------------------------------
    topSecWrap--under
  ----------------------------------*/
  .topSecWrap--under {
    padding: 24px 0 0;
    p {
      width: calc(100% - 40px);
      margin: 0 auto 15px;
    }
  }
  .ttl--underLine.merit {
    &::before {
      width: 24px;
      height: 22px;
      margin: 0 auto 7px;
    }
  }
  .meritBox {
    padding: 28px 20px 30px;
    margin: 30px 0 0;
    &__ttl {
      font-size: 1.6rem!important;
      margin:  0 0 17px!important;
      &::before {
        content: "";
        width: 35px;
        height: 39px;
        margin: 0 auto 11px;
      }
    }
    .meritList {
      margin: 0;
      li {
        font-size: 1.4rem;
        line-height: 1.658333;
        padding: 10px 15px 10px 30px;
        position: relative;
        &::before {
          content: "";
          width: 15px;
          height: 15px;
          margin-right: 7px;
          position: absolute;
          top: 15px;
          left: 7px;
        }
        & + li {
          margin: 7px 0 0;
        }
      }
    }
  }
  .popularArticle {
    width: calc(100% - 40px);
    min-height: 144px;
    margin: 40px auto 25px;
    &::before {
      content: "";
      width: 125px;
      height: 169px;
      background: url(/images/bg_popularArticle_sp.png) no-repeat center center;
      background-size: contain;
      margin-top: 0;
      position: absolute;
      bottom: 0;
    }
    &__in {
      width: 100%;
      float: none;
      padding: 18px 15px 12px 130px;
    }
    .data {
      text-align: left;
      margin: 12px 0 0;
      font-size: 1.2rem;
      color: #747169;
    }
  }
  .time {
    display: block;
    &::before {
      width: 14px;
      height: 11px;
      background-position: center center;
    }
  }
  .view {
    display: block;
    margin-left: 0;
    &::before {
      width: 14px;
      height: 7px;
    }
  }
  /* =================================
    article list
  ================================= */
  .articleListWrap {
    padding: 0 10px 30px;
  }
  .articleList {
    li {
      display: block;
      .thumb {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      & + li {
        margin: 20px 0 0;
      }
    }
    &__item {
      width: 100%;
      background: #fcf3f8;
      padding: 15px 15px 40px;
      position: relative;
      a {
        font-size: 1.4rem;
        line-height: 1.4;
      }
      .data {
        margin: 15px 0 0;
        padding: 5px 5px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        right: 0;
        background: #f7f6f1;
        .view {
          margin-left: 10px;
        }
      }
    }
  }
  .pagenation {
    display: block;
    text-align: center;
    .pager {
      margin: 0 0 10px;
      li {
        a {
          padding: 7px 9px;
        }
        span {
          padding: 7px 9px;
        }
        & + li {
          margin-left: 3px;
        }
      }
    }
  }

  /* =================================
    article detail
  ================================= */
  .article__detail {
    padding: 17px 10px;
    .data {
      margin: 0 0 15px;
      .categoryLabel {
        font-size: 1.2rem;
        padding: 5px 8px;
      }
    }
    .tocBox {
      margin: 15px 0 20px;
      padding: 15px 15px 20px;
      &__ttl {
        font-size: 1.6rem;
        &::before {
          width: 16px;
          height: 18px;
        }
      }
      .tocList {
        margin: 15px 0 0;
        &__item {
          a {
            font-size: 1.5rem;
            line-height: 1.6;
          }
          & + .tocList__item {
            margin: 12px 0 0;
          }
        }
      }
    }
    h1 {
      font-size: 2rem;
      margin: 0 0 15px;
    }
    h2 {
      font-size: 1.7rem;
      line-height: 1.46666;
      padding: 12px;
      margin: 30px 0 15px;
    }
    h3 {
      font-size: 1.6rem;
      line-height: 1.5;
      padding-bottom: 5px;
      margin: 30px 0 10px;
    }
    h4 {
      font-size: 1.5rem;
      line-height: 1.58333;
      padding: 10px;
      margin: 0 0 11px;
    }
    h5 {
      font-size: 1.5rem;
      line-height: 1.56333;
      padding: 0 0 10px;
      margin: 0 0 10px;
    }
    img {
      margin: 0 auto 10px;
    }
    table {
      margin: 0 0 10px;
      overflow-x: auto;
      white-space: nowrap;
      display: block;
      caption {
        font-size: 1.5rem;
        text-align: left;
        margin: 0 0 8px;
      }
      th {
        font-size: 1.4rem;
        min-width: 120px;
      }
      td {
        font-size: 1.4rem;
      }
    }
    ul:not([class]) {
      margin: 10px auto 20px;
      li {
        font-size: 1.4rem;
        padding: 7px 0 4px 25px;
        &::before {
          width: 12px;
          height: 12px;
          top: 8px;
        }
      }
    }
    ol {
      margin: 10px auto 20px;
      li {
        font-size: 1.4rem;
        padding: 10px 0 0 35px;
        &::before {
          font-size: 1.2rem;
          padding: 6px 8px;
          left: 3px;
          top: 10px;
        }
        &+ li {
          margin: 10px 0 0;
        }
      }
    }
    .related {
      padding: 0 10px 22px;
      margin: 40px 0 30px;
      &__list {
        margin: 0;
        &__item {
          padding: 10px;
          .thumb {
            width: 90px;
            img {
              margin: 0;
            }
          }
          .related__txt {
            width: calc(100% - 90px);
            background: #fff;
            padding: 0 0 0 10px;
            margin: 0;
            a {
              font-size: 1.4rem;
              line-height: 1.25;
              color: $font-color-base;
            }
          }
        }
      }
      &__ttl {
        font-size: 1.3rem!important;
        line-height: 1.6153;
        padding: 6px 8px;
        margin: 0 0 5px!important;
        text-align: left;
        top: -10px;
        &::after {
          width: 12px;
          height: 18px;
          left: 24px;
          bottom: -10px;
        }
      }
    }
    .pointBox {
      background: url(/images/bg_point.png) no-repeat right 22px,#fcf3f8;
      background-size: 80px auto;
      padding: 0 10px 10px;
      margin: 45px 0 20px;
      &__ttl {
        font-size: 1.3rem;
        padding: 6px 8px;
        margin: 0 0 5px;
        top: -10px;
        &::after {
          content: "";
          width: 12px;
          height: 18px;
          top: 17px;
        }
      }
      h5 {
        width: calc(100% - 80px);
      }
    }
    .imgBox {
      margin: 20px 0;
    }
  }
  .related {
    padding: 24px 10px 22px;
    margin: 20px 0 0;
    &__ttl {
      font-size: 1.7rem;
      margin: 0 0 15px;
      &::before {
        content: "";
        width: 34px;
        height: 32px;
        margin: 0 auto 10px;
      }
    }
    &__list {
      &__item {
        .thumb {
          width: 200px;
        }
        .related__txt {
          width: 100%;
          padding: 12px;
        }
        & + .related__list__item {
          margin: 11px 0 0;
        }
      }
    }
  }
  .underSecBox {
    border-top: 4px solid #f3eee5;
    padding: 13px 10px 15px;
  }

  /* =================================
    税理士一覧
  ================================= */
  .listCard {
    border: 2px solid #f3eee5;
    padding: 14px 20px;
    display: block;
    box-sizing: border-box;
    & + .listCard {
      margin: 11px auto 0;
    }
    &__r {
      width: 100%;
    }
    &__l {
      width: 100%;
    }
    .btn--mailForm {
      font-size: 1.2rem;
      display: block;
      padding: 8px 0;
      margin: 10px 0 0;
      position: static;
      text-align: center;
      &::before {
        content: "";
        width: 12px;
        height: 8px;
        margin-right: 9px;
      }
    }
  }
  .tagBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 7px;
    li {
      font-size: 1.1rem;
      padding: 6px 7px;
      margin-right: 4px;
      & + li {
        margin-left: 0;
      }
    }
  }
  .name {
    margin: 14px 0;
    h2 {
      font-size: 1.9rem;
      line-height: 1.245;
    }
    a {
      font-size: 1.9rem;
      line-height: 1.245;
    }
    .catch {
      font-size: 1.1rem;
      margin: 0 0 8px;
    }
  }
  .info {
    margin:  0 0 10px;
    address {
      padding-left: 25px;
    }
    .address {
      td::before {
        width: 13px;
        height: 18px;
        left: 3px;
      }
    }
    .access {
      td::before {
        width: 15px;
        height: 18px;
        left: 3px;
      }
    }
  }
  .feature {
    margin: 0 0 10px;
    dt {
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1.5;
      padding: 5px 8px;
      width: 5rem;
      text-align: center;
    }
    dd {
      margin-left: 10px;
    }
  }
  .contactBox {
    padding: 6px 4px 4px;
    display: block;
    .tel {
      &Ttl {
        font-size: 1.1rem;
        color: $accent-color;
        margin: 0 0 9px 5px;
      }
      a {
        address {
          font-size: 2.5rem;
          margin-left: 5px;
          &::before {
            content: "";
            width: 16px;
            height: 16px;
            margin-right: 7px;
            animation-duration: 2s;
            animation-timing-function: liner;
            animation-iteration-count: infinite;
            animation-name: vibrate02;
          }
        }
      }
    }
    .receptionTime {
      font-size: 1rem;
      font-weight: bold;
      padding: 8px 7px;
      display: flex;
      max-width: auto;
      max-width: initial;
      dt {
        color: $accent-color;
        margin: 0 7px 0 0;
      }
      dd br {
        display: none;
      }
    }
  }
  /* =================================
    税理士詳細
  ================================= */
  .clientSummary {
    padding: 10px 10px 25px;
    &::after {
      border-width: 17px 14px 0 14px;
      bottom: -17px;
    }
    &__in {
      display: block;
      padding: 12px 18px;
      border: 2px solid #f3eee5;
      .tagBox {
        position: static;
      }
    }
    &__r {
      width: 100%;
      margin: 0;
      text-align: center;
    }
    &__l {
      width: 100%;
      padding-top: 0;
    }
  }
  .clientPoint {
    background: #f8f5f6;
    padding: 24px 10px 30px;
    &::before {
      width: 28px;
      height: 38px;
      margin: 0 0 3px;
    }
    &__ttl {
      font-size: 1.7rem;
      line-height: 1.41176;
      margin: 0 40px 22px;
      position: relative;
      &::before {
        width: 30px;
        height: 40px;
        position: absolute;
        left: -30px;
        bottom: 0;
      }
      &::after {
        width: 30px;
        height: 40px;
        position: absolute;
        right: -30px;
        bottom: 0;
      }
    }
    &__box {
      padding: 3px;
      &__in {
        padding: 15px 17px 12px;
      }
      p {
        font-size: 1.4rem;
        padding-bottom: 5px;
        line-height: 2;
      }
      ul {
        padding: 0px 9px 6px;
        margin: 12px 0 0;
        li {
          padding: 13px 7px 13px 23px;
          &::before {
            width: 11px;
            height: 11px;
            top: 18px;
            left: 3px;
          }
        }
      }
    }
  }
  /*map*/
  .clientMap {
    padding: 14px 10px 24px;
    .ttl--underLine.map {
      &::before {
        content: "";
        width: 15px;
        height: 22px;
        margin: 0 auto 8px;
      }
    }
    &__inner {
      outline: 4px solid rgba(134, 192, 22, 0.4);
      iframe {
        height: 230px;
      }
    }
  }
  /*料金体系*/
  .clientPrice {
    padding: 18px 0 20px;
    .ttl--underLine.price {
      &::before {
        width: 15px;
        height: 21px;
        margin: 0 auto 10px;
      }
    }
    &__in {
      padding: 0 20px;
      h3 {
        font-size: 1.6rem;
        line-height: 1.2;
        margin: 30px 0 15px;
        text-align: left;
      }
    }
    table {
      white-space: nowrap;
      overflow-x: scroll;
      display: block;
      tr + tr {
        border-top: 1px solid #dcd0d3;
      }
      thead th {
        padding: 9px 0;
      }
      th {
        font-size: 1.4rem;
        padding: 9px 14px;
        text-align: left;
        width: 100%;
      }
      td {
        font-size: 1.3rem;
        padding: 12px 14px;
        width: 100%;
      }
    }
  }
  /*suggest*/
  .clientSuggest {
    padding: 20px 20px 15px;
    .ttl--suggest {
      font-size: 1.9rem;
      > span {
        font-size: 1.7rem;
        margin: 0 0 8px;
        &::before {
          width: 15px;
          height: 24px;
        }
        &::after {
          width: 15px;
          height: 24px;
        }
        span {
          font-size: 2.2rem;
          &::before {
            font-size: 1.8rem;
            top: -12px;
            left: 2px;
          }
          &::after {
            font-size: 1.8rem;
            position: absolute;
            top: -12px;
            right: 2px;
          }
        }
      }
    }
    .contactBox {
      padding: 11px 10px 8px;
      margin: 14px 0 0;
      .tel {
        position: relative;
        &::after {
          content: "";
          width: 14px;
          height: 23px;
          background: url(/images/icon/icon_aroowR02.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .tel a address {
        font-size: 2.7rem;
        &::before {
          width: 18px;
          height: 18px;
          animation-duration: 2s;
          animation-timing-function: liner;
          animation-iteration-count: infinite;
          animation-name: vibrate02;
        }
      }
      .receptionTime {
        font-size: 1.2rem;
      }
    }
    .btn--mailForm {
      font-size: 1.3rem;
      padding: 10px 22px 10px 26px;
      border: 1px solid $accent-color;
      margin: 15px 0 0;
      &::before {
        content: "";
        width: 14px;
        height: 9px;
      }
    }
  }
  .clientDetail {
    background: #fff;
    padding: 0 10px 0;
    margin: 37px 0 0;
    &__ttl {
      font-size: 1.5rem;
      padding: 6px 9px;
      top: -13px;
      &::after {
        content: "";
        width: 6px;
        height: 8px;
        bottom: -7px;
        left: 0;
      }
    }
    table {
      white-space: normal;
      th {
        font-size: 1.4rem;
      }
      td {
        font-size: 1.3rem;
      }
    }
  }
  .underContact {
    margin: 0 auto;
    // padding: 20px 15px 183px;
    padding: 20px 15px 30px;
  }
  .underContact--tel {
    background: url(/images/bg_border_green02.jpg) repeat left top;
    padding: 10px 20px 15px;
    // opacity: 0;
    @include animation-base;
    &__ttl {
      font-size: 1.6rem;
      font-weight: bold;
      color: $accent-color;
      text-align: center;
      margin: 0 0 8px;
      &::before {
        content: "";
        width: 11px;
        height: 25px;
        background: url(/images/ttl_slashL03.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
      }
      &::after {
        content: "";
        width: 11px;
        height: 25px;
        background: url(/images/ttl_slashR03.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .contactBox {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 1px 2px 0px rgba(5, 1, 1, 0.4);
      border: none;
      padding: 11px 10px 8px;
      margin: 8px 0 0;
      .tel {
        position: relative;
        & a address {
          font-size: 2.7rem;
          &::before {
            width: 18px;
            height: 18px;
            animation-duration: 2s;
            animation-timing-function: liner;
            animation-iteration-count: infinite;
            animation-name: vibrate02;
          }
        }
        &::after {
          content: "";
          width: 7px;
          height: 12px;
          background: url(/images/icon/icon_aroowR02.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .receptionTime {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 10px 0 0;
      }
    }
    &.fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      opacity: 1;
      width: 100%;
    }
  }
  /* =================================
    お問い合わせ
  ================================= */
  .underSecBox.contact {
    margin-bottom: 0;
  }
  .contactSec__ttl {
    &::before {
      content: "";
      width: 19px;
      height: 14px;
    }
  }
  .contactForm {
    border: 2px solid #e8e3d9;
    padding: 13px 13px 20px;
    .step {
      justify-content: space-between;
      margin: 0 0 27px;
      li {
        width: calc(33.33333% - 11.3333px);
        .num {
          font-size: .8rem;
          line-height: 19px;
          width: 100%;
          display: block;
        }
        .txt {
          font-size: 1rem;
          line-height: 23px;
          width: 100%;
          display: block;
        }
        & + li {
          margin-left: 17px;
        }
        &:nth-child(-n + 2)::after {
          border-width: 2px 0 2px 6px;
          top: initial;
          bottom: 10px;
          right: -6px;
          transform: translateY(0);
        }
      }
    }
    tr {
      border-top: none;
      &:last-child {
        border-bottom: none;
      }
    }
    th {
      font-size: 1.2rem;
      font-weight: bold;
      background: transparent;
      padding: 10px 0;
      display: flex;
      align-items: center;
      width: 100%;
    }
    td {
      padding: 0;
      display: block;
      width: 100%;
      textarea {
        min-height: 90px;
      }
    }
    .required {
      font-size: .9rem;
      padding: 3px 4px;
      margin-left: 6px;
    }
    .confirmingTxt {
      font-size: 1.2rem;
      line-height: 1.65833;
      text-align: left;
      margin: 20px 0 18px;
    }
  }
  .completedForm {
    .completedMessage {
      font-size: 1.4rem;
      margin: 14px 0 0;
    }
    p {
      font-size: 1.2rem;
      text-align: left;
      margin: 20px 0 0;
    }
    table {
      width: 100%;
      margin: 40px auto 25px;
    }
    th,td {
      width: auto;
    }
    th {
      font-size: 1.5rem;
      padding: 12px 10px;
      background: url(/images/bg_border_green02.jpg) repeat left top;
    }
    td {
      padding: 12px 10px;
      border-bottom: 1px dashed #dcd0d3;
      .tel {
        address {
          font-size: 2.7rem;
          &::before {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            animation-duration: 2s;
            animation-timing-function: liner;
            animation-iteration-count: infinite;
            animation-name: vibrate02;
          }
        }
      }
      .receptionTime {
        margin: 5px 0 0;
        dt {
          font-size: 1.2rem;
          font-weight: bold;
          color: $accent-color;
        }
        dd {
          font-size: 1.2rem;
          margin-left: 0px;
        }
        dt,dd {
          display: block;
        }
      }
    }
  }
  /* =================================
    single page
  ================================= */
  .singleCont {
    border: 2px solid #e8e3d9;
    padding: 13px 13px 20px;
  }
}