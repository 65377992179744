@charset "utf-8";
body {
  background: #000;
  word-break: break-all;
}
//変数の定義
$base-width: 1080px;
$display-width-s: 1024px;
$base-color: #fff;
$accent-color: #c13fb0;
$accent-color02: #f06595;
$font-color-base: #201a0a;
$font-color-link: #000;

@mixin mq-sp {
    @media screen and (min-width: 0) and (max-width: $display-width-s) {
        @content;
    }
}
@mixin mq-desktop {
    @media screen and (min-width: $display-width-s) {
        @content;
    }
}

//フォントの定義
%font {
  color: $font-color-base;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  @include mq-sp{ font-size: 1.4rem; } //::スマホ
}
%font-mincho {
  font-family: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

//タイトルの定義
@mixin ttl-base($size, $lineHeight:1, $weight:700, $color:#fff) {
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $weight;
  color: $color;
  @include mq-sp{ font-size: $size/1.5;}
}

//余白の定義
%margin-wrap {
  @include mq-sp{//::スマホ
    margin: 0 8px;
  }
}
%margin-wrap-in {
  margin: 0 24px;
  @include mq-sp{//::スマホ
    margin: 0 12px;
  }
}
