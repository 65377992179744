@charset "utf-8";

/*=================================
  footer
=================================*/
.footer {
  position: relative;
  margin: 56px 0 0;
  .fNav {
    background: #f5f8f5;
    padding: 23px 2.5%;
    position: relative;
    &__list {
      max-width: $base-width;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      line-height: 1;
      &__item {
        position: relative;
        a {
          font-size: 1.3rem;
          font-weight: bold;
          color: #201a0a;
          padding: 0 16px;
        }
      }
    }
  }
  .copy {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    background: linear-gradient(45deg, #027af5 0%, #f06595 90%);
    display: block;
    text-align: center;
    padding: 16px 2.5% 10px;
  }
}
.pageTop {
  position: fixed;
  right: calc((100% - 1260px) / 2);
  bottom: 126px;
  z-index: 1000;
  .btn--pageTop {
    font-size: 1.3rem;
    color: $accent-color;
    font-weight: bold;
    background: #fcf3f8;
    padding: 11px;
    text-align: center;
    width: 62px;
    display: block;
    border-radius: 2px;
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 12px 4px;
      border-color: transparent transparent $accent-color transparent;
      display: block;
      margin: 0 auto 5px;
    }
  }
  &:hover {
    .btn--pageTop {
      color: #fcf3f8;
      background: $accent-color;
      &::before {
        content: "";
        border-color: transparent transparent #fcf3f8 transparent;
      }
    }
  }
}
@media screen and (max-width: 1290px){
  .pageTop {
    right: 5px;
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    footer
  =================================*/
  .footer {
    margin: 0;
    .fNav {
      padding: 15px 20px 12px;
      &__list {
        display: block;
        &__item {
          a {
            font-size: 1.2rem;
            padding: 0;
            line-height: 2;
            display: inline-block;
          }
        }
      }
    }
    .copy {
      font-size: 1rem;
      font-weight: normal;
      padding: 7px 2.5% 5px;
    }
  }
  .pageTop {
    display: none!important;
  }
}